@define-mixin footerVariant $backgroundColor, $iconsColor, $iconHoverColor: var(--whiteBaseColor) {
    background-color: $backgroundColor;

    & .SocialLinkIcon {
        @mixin fixedRatioSvgIcon 24px, $iconsColor;
    }

    & .SocialLinksListItem {
        &:hover {
            & .SocialLinkIcon svg {
                fill: $iconHoverColor;
            }
        }
    }
}

.Footer {
    @mixin hideOnPrint;
    @mixin footerVariant var(--blackBaseColor), var(--blackLighten30Color);
    min-height: 400px;
    color: var(--whiteBaseColor);
    padding: 48px 0 32px;

    @media (--tablet) {
        padding-top: 64px;
    }
}

.Inner {
    @mixin wrapper;
}

.Logo {
    margin-bottom: 96px;
}

.Heading {
    @mixin p20;
    margin-bottom: 24px;
}

.Sections {
    margin-bottom: 64px;

    @media (--tablet) {
        margin-bottom: 96px;
        display: flex;
    }
}

.LinksSections {
    @mixin clearList;

    @media (--tablet) {
        lost-column: 9/12;
        display: flex;
        flex-wrap: wrap;
    }
}

.LinksSection {
    margin-bottom: 48px;

    @media (--tablet) {
        lost-column: 1/3;
    }

    @media (--desktop) {
        lost-column: 1/4;
    }
}

.LinksList {
    @mixin clearList;
}

.LinksListItem {
    @mixin p30;
    padding: 8px 0;
}

.SocialLinksSection {
    @media (--tablet) {
        lost-column: 3/12;
    }
}

.SocialLinksList {
    @mixin clearList;
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;

    @media (--desktop) {
        margin-left: -20px;
    }
}

.SocialLinksListItem {
    @mixin centerElements;
    width: 48px;
    height: 48px;
    margin-right: 16px;

    @media (--tablet) {
        margin-right: 0;
    }

    @media (--desktop) {
        width: 64px;
        height: 64px;
    }
}

.SocialLinkIcon {
    @mixin fixedRatioSvgIcon 24px;
    @mixin svgIconFillAnimation;
}

.Copyright {
    @mixin p50;
    color: var(--blackLighten40Color);
}

.Variant--default {
    @mixin footerVariant var(--blackBaseColor), var(--blackLighten30Color);
}

.Variant--red {
    @mixin footerVariant var(--primaryHKDarken40Color), var(--primaryHKLighten40Color);
}
