.KristianiaLogo {
    color: var(--blackBaseColor);
    width: var(--logoWidth);
    height: var(--logoHeight);

    &:global(.SvgIcon) {
        @mixin svgIcon var(--logoWidth), var(--logoHeight);
    }
}

.Variant--medium {
    &.Variant--symbol {
        --logoWidth: 33px;
        --logoHeight: 36px;
    }

    &.Variant--horizontal {
        --logoWidth: 137px;
        --logoHeight: 44px;
    }
}

.Variant--large {
    &.Variant--symbol {
        --logoWidth: 44px;
        --logoHeight: 48px;
    }

    &.Variant--horizontal {
        --logoWidth: 175px;
        --logoHeight: 56px;
    }
}

.Variant--white {
    filter: invert(100%);
}

.Variant--black {
    /* Default */
}
